<template>
    <el-drawer
            :with-header="false"
            :visible.sync="editFormShow"
            direction="rtl"
            size="50%"
            :before-close="handleEditFormClose"
    >
        <div style="padding: 20px;">

            <el-input v-model="editForm.title" placeholder="请输入标题" style="margin-bottom: 12px"></el-input>

            <div v-if="editForm.type == types.url">
                <el-input v-model="editForm.url" placeholder="请输入网址" v-if="editForm.type == types.url"
                          style="margin-bottom: 12px"></el-input>
            </div>
            <div v-if="editForm.type == types.note" style="margin-bottom: 12px;" >
                <el-tabs v-model="editForm.format">
                    <el-tab-pane label="纯文本" name="PLAIN">

                        <el-input v-model="editForm.content" type="textarea" placeholder="请输入内容"
                                  v-if="editForm.type == types.note"
                                  :rows="Math.floor(editorBoxHeight / 21)"></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="MarkDown" name="MARKDOWN">
                        <mavon-editor v-model="editForm.content" :style="{height: editorBoxHeight + 'px', border: '1px solid #DCDFE6', borderRadius: '4px'}"></mavon-editor>
                    </el-tab-pane>

                </el-tabs>
            </div>
            <el-button type="primary" @click="handleSave">保存</el-button>
        </div>


    </el-drawer>
</template>

<script>
    import {apiPost,apiGet} from '@/utils/api.js'
    import {types} from '@/utils/config.js'

    export default {
        name: "Edit",
        data() {
            return {
                types,

                editForm: {
                    id: 0,
                    pid: 0,
                    type: "",
                    title: "",
                    content: "",
                    format: "",
                    url: "",
                },

                editFormShow: false,

            }
        },
        computed: {
            editorBoxHeight(){
                return (this.$store.state.windowInnerSize.height - 200)
            },
        },
        methods: {

            handleSave() {
                if (typeof this.editForm.pid == 'object') {
                    this.editForm.pid = this.editForm.pid.pop()
                }

                apiPost('/note', this.editForm).then(result => {
                    if (result.code === 0) {
                        this.$store.dispatch("loadNotes")

                        this.handleEditFormClose()
                    }
                })
            },

            handleEditFormClose() {
                this.editFormShow = false
            },

            async handleEdit(item) {
                this.editForm = {
                    id: item.id,
                    pid: item.pid,
                    type: item.type,
                    format: item.format || 'PLAIN',
                    title: item.title,
                    url: item.url,
                    content: "",
                }

                if (item.type === types.note && item.id > 0) {
                    let res = await apiGet(`/note/${item.id}`)
                    if (res.code === 0) {
                        this.editForm.content = res.data.content
                    } else {
                        return
                    }
                }

                this.editFormShow = true
            },

        }
    }
</script>

<style scoped>

</style>
