<template>
  <div v-if="items.length" class="menu-box">
    <div v-for="v in items" v-bind:key="v.id"
         @click="handleItemClick(v)" @contextmenu="handleRightClick(v, $event)" style="display: inline-block;position: relative;text-align: center;margin: 6px;">
      <el-card
               draggable="true"
               @dragstart="handleDragStart(v)" @dragend="handleDragEnd(v)" :class="dragging ? 'dragging' : ''"
      >
        <el-image style="width: 32px; height: 32px" :src="noteIcon(v)" @error="noteIconError"></el-image>
        <div style="text-align: center">{{ v.short || v.title }}</div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {apiPost} from '@/utils/api.js'
import {noteIcon, noteIconError} from '@/utils/tool';

export default {
  name: "Launcher",

  data() {
    return {
      dragging: false,
      dragOver: {
        id: null,
        type: null
      },
      dragItem: null,
    }
  },

  computed: {
    items() {
      return this.$store.state.launcher
    },
  },

  methods: {
    noteIcon,
    noteIconError,
    handleRightClick(v, e) {
      console.log(v)
      this.$emit('note-right-click', v, e, 'launcher')
    },

    handleItemClick(v){
      this.$emit('note-click', v)
    },

    handleDragStart(v) {
      this.dragOver.id = null
      this.dragOver.type = null
      this.dragging = true
      this.dragItem = v
    },
    handleDragEnd() {
      this.dragging = false
    },
    handleDragOver(v, type) {
      this.dragOver.id = v.id
      this.dragOver.type = type
    },
    handleDragLeve(v) {
      if(this.dragOver.id == v.id){
        this.dragOver.id = null
        this.dragOver.type = null
      }
    },
    handleDragDrop() {
      if(this.dragItem.id != this.dragOver.id){
        // 删除
        for (let i = 0; i < this.data.length; i++) {
          if(this.data[i].id == this.dragItem.id){
            this.data.splice(i, 1)
            break
          }
        }

        // 放入
        for (let i = 0; i < this.data.length; i++) {
          if(this.data[i].id == this.dragOver.id){
            if(this.dragOver.type == 'before'){
              this.data.splice(i, 0, this.dragItem)
            }

            if(this.dragOver.type == 'after'){
              this.data.splice(i + 1, 0, this.dragItem)
            }

            break
          }
        }

        // 保存
        let orders = this.data.map(v => v.id)
        apiPost('/mark/fixed_orders', {orders})
      }
    },
  }

}
</script>

<style>
.menu-box {
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 1px 3px 1px;
  margin: 24px auto 0;
  max-width: 960px;
  padding: 8px 0;
}

.menu-box .el-menu-item, .menu-box .el-submenu, .menu-box .el-submenu__title {
  height: 21px !important;
  line-height: 21px !important;
}

.menu-box .el-menu.el-menu--horizontal {
  display: inline-block;
  border-bottom: none;
}

.menu-box .el-menu--horizontal > .el-submenu.is-active .el-submenu__title, .menu-box .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

.drag-over {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dragging .drag-over{
  display: flex;
}

.drag-over > div{
  flex:1;
  height: 100%
}
.drag-over .on:nth-child(1) {
  border-left: 1px solid #4CA4FF;
}
.drag-over .on:nth-child(2) {
  border-right: 1px solid #4CA4FF;
}
</style>
