<template>
    <div>
        <div class="bar-box" @click="handleListShow">
            <div class="search-input"><i class="el-icon-search"></i>
                <el-input v-model="searchWords" placeholder="搜索一下"></el-input>
                <i class="el-icon-circle-close" v-if="searchWords" @click="searchWords = ''"></i></div>
        </div>

        <div class="search-list-box" v-show="showList" @click="handleListHide">
            <div class="search-check-box" @click.stop>
                <el-checkbox-group v-model="checkList">
                    <el-checkbox label="FOLDER">文件夹</el-checkbox>
                    <el-checkbox label="NOTE">笔记</el-checkbox>
                    <el-checkbox label="URL">链接</el-checkbox>
                    <el-checkbox label="FILE">文件</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="search-note-box" @click.stop>
                <div v-for="v in items" v-bind:key="v.id"
                     @contextmenu.stop="handleRightClick(v, $event)"
                     @click="handleItemClick(v)"
                >
                    <file-drag class="note-item" :data="v">
                        <img class="icon" :src="noteIcon(v)" @error="noteIconError"/>
                        {{ v.title }}
                        <span class="url" v-if="v.url">{{ decodeURI(v.url) }}</span>
                    </file-drag>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FileDrag from "./FileDrag";
    import {types} from '@/utils/config.js'
    import {formatTree, noteIcon, noteIconError} from "@/utils/tool";

    export default {
        name: "Search",
        components: {FileDrag},
        data() {
            return {
                showList: false,
                searchWords: '',
                checkList:['FOLDER', 'NOTE', 'URL', 'FILE']
            }
        },
        computed: {

            items() {
                if (this.searchWords) {

                    let reg = new RegExp(this.searchWords, 'i')
                    let results = this.$store.state.notes.filter(v => ((reg.test(v.title) || reg.test(v.url)) && this.checkList.indexOf(v.type) >= 0))

                    return results.map(v => {
                        v = JSON.parse(JSON.stringify(v))
                        v.children = formatTree(this.$store.state.notes, v.id, false)
                        return v
                    })
                }

                return []
            }
        },

        methods: {
            noteIcon,
            noteIconError,

            handleListShow(){
                this.showList = true
            },

            handleListHide(){
                this.showList = false
            },

            handleItemClick(v) {
                if(v.type == types.folder){
                    this.handleListHide()
                }

                this.$emit('note-click', v)
            },

            handleRightClick(v, e) {
                if (v == 'current') {
                    let id = this.currentId ?? this.folderId

                    if (id) {
                        v = this.$store.state.notes.filter(n => n.id == id)[0]
                    } else {
                        v = {id: "0", type: types.folder}
                    }

                    this.$emit('note-right-click', v, e, 'folder')

                    return
                }

                this.$emit('note-right-click', v, e, 'normal')
            },

        }
    }
</script>

<style scoped>

    .bar-box {
        align-items: center;
        background-color: rgb(51, 103, 214);
        color: #fff;
        display: flex;
        height: 56px;
    }

    .search-input {
        background: rgba(0, 0, 0, 0.22);
        border-radius: 2px;
        cursor: text;
        padding-inline-end: 0;
        width: 680px;
        align-items: center;
        display: flex;
        height: 40px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0.4, 0, 0.2, 1);
        margin: 0 auto;
    }

    .search-input .el-input__inner {
        background: rgba(0, 0, 0, 0);
        border: none;
        color: #fff;
        padding: 0 5px;
    }

    .search-input .el-icon-search {
        font-size: 16px;
        width: 16px;
        height: 16px;
        margin: 10px;
        cursor: pointer;
    }

    .search-input .el-icon-circle-close {
        font-size: 20px;
        width: 20px;
        height: 20px;
        margin: 10px;
        cursor: pointer;
    }

    .search-list-box{
        position: fixed;
        top: 56px;
        height: calc(100% - 56px);
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 999;
        overflow: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .search-check-box{
        width: 960px;
        background-color: #FFF;
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid #E0E0E0;
    }

    .search-note-box {
        margin-bottom: 160px;
        overflow-y: auto;
        padding-bottom: 100px;
        box-sizing: border-box;
        width: 960px;
        background-color: #FFF;
        border-radius: 0 0 6px 6px;
    }

    .search-note-box > div {
        overflow: hidden;
        width: 100%;
    }

    .note-item {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #525252;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 800px;
        box-sizing: border-box;
        white-space: pre;
        cursor: pointer;
    }

    .note-item > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .note-item .url {
        display: none;
        color: #9DA3AC;
        padding-left: 6px;
    }

    .note-item:hover {
        background-color: rgb(245, 247, 250);
    }

    .note-item:hover .url {
        display: inline;
    }

</style>
