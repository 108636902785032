<template>
    <div @click="handleGlobalClick">
        <!-- 头部搜索栏 -->
        <search @note-right-click="handleRightClick" @note-click="handleOpen"></search>

        <!-- 启动器 -->
        <launcher @note-right-click="handleRightClick" @note-click="handleOpen"></launcher>

        <!-- 主体 -->
        <el-tabs v-model="activeTabName" @tab-click="handleTabClick" class="tabs-box" ref="mainBody"
                 :style="{height:mainHeight}">
            <el-tab-pane label="轻松笔记" name="home">
                <folder @note-right-click="handleRightClick" @note-click="handleOpen"></folder>
            </el-tab-pane>
            <el-tab-pane v-for="t in tabs" :label="t.title" :name="'TAB:' + t.id" v-bind:key="t.id">
                <folder :id="t.id" :title="t.title" @note-right-click="handleRightClick"
                        @note-click="handleOpen"></folder>
            </el-tab-pane>
        </el-tabs>

        <!-- 右键菜单 -->
        <context-menu @menu-click="handleContextMenuClick"></context-menu>

        <!-- 编辑 -->
        <edit ref="editCom"></edit>
        <el-drawer
                :with-header="false"
                :visible.sync="editFormShow"
                direction="rtl"
                :before-close="handleEditFormClose"
        >
            <div style="padding: 20px;">

                <el-input v-model="editForm.title" placeholder="请输入标题" style="margin-bottom: 12px"></el-input>
                <el-input v-model="editForm.url" placeholder="请输入网址" v-if="editForm.type == types.url"
                          style="margin-bottom: 12px"></el-input>
                <el-input v-model="editForm.content" type="textarea" placeholder="请输入内容"
                          v-if="editForm.type == types.note"
                          style="margin-bottom: 12px;" :rows="30"></el-input>
                <el-button type="primary" @click="handleSave">保存</el-button>
            </div>


        </el-drawer>


        <!-- 重命名 -->
        <el-dialog
                title="重命名"
                width="30%"
                :visible="renameFormShow"
                :before-close="handleRenameClose">
            <el-input v-model="renameForm.title" placeholder="请输入标题" style="margin-bottom: 12px"></el-input>
            <el-button type="primary" @click="handleRenameSave">保存</el-button>
        </el-dialog>

        <!-- 简称 -->
        <el-dialog
                title="简称"
                width="30%"
                :visible="shortnameFormShow"
                :before-close="handleShortnameClose">
            <el-input v-model="shortnameForm.name" placeholder="请输入简称" style="margin-bottom: 12px"></el-input>
            <el-button type="primary" @click="handleShortnameSave">保存</el-button>
        </el-dialog>

        <!-- 图标 -->
        <el-dialog
                width="218px"
                :visible="iconFormShow"
                :before-close="handleIconClose">
            <el-upload
                    class="avatar-uploader"
                    action="/mark/icon"
                    name="icon"
                    :data="{id:iconForm.id}"
                    :show-file-list="false"
                    :on-success="handleIconUploaded">
                <img v-if="iconForm.icon" :src="noteIcon(iconForm)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-dialog>

        <!-- 属性 -->
        <el-dialog
                title="属性"
                width="500px"
                v-if="propObject"
                :visible="true"
                :before-close="handlePropClose">
            <el-descriptions :column="1" border>
                <el-descriptions-item label="图标"><img style="width: 24px;height: 24px;" :src="noteIcon(propObject)"
                                                      @error="noteIconError"/></el-descriptions-item>
                <el-descriptions-item label="名称">{{ propObject.title }}</el-descriptions-item>
                <el-descriptions-item label="简称">{{ propObject.short }}</el-descriptions-item>
                <el-descriptions-item label="类型">{{ propObject.type }}</el-descriptions-item>
                <el-descriptions-item label="目录">{{ pathName(propObject.pid) }}</el-descriptions-item>
                <el-descriptions-item label="网址" v-if="propObject.url">{{ propObject.url }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ propObject.createdAt }}</el-descriptions-item>
                <el-descriptions-item label="修改时间">{{ propObject.updatedAt }}</el-descriptions-item>
            </el-descriptions>
        </el-dialog>

        <!-- 笔记显示 -->
        <el-drawer
                :with-header="false"
                :visible="true"
                v-if="contentShow"
                size="50%"
                :before-close="handleContentShowClose">
            <div class="note-content-title">{{ contentData.title }}</div>
            <div v-if="contentData.format == 'MARKDOWN'">
                <mavon-editor v-model="contentData.content" :toolbarsFlag="false" :subfield="false" defaultOpen="preview"></mavon-editor>
            </div>
            <div v-else class="note-content-box">{{ contentData.content }}</div>
        </el-drawer>

        <!-- 登录窗口 -->
        <login/>
    </div>
</template>

<script>
    import {apiDelete, apiGet, apiPost, apiPut} from '@/utils/api.js'
    import {actions, types} from '@/utils/config.js'
    import Launcher from "./component/Launcher";
    import Folder from "./component/Folder";
    import {noteIcon, noteIconError} from "../utils/tool";
    import Login from "./component/Login";
    import ContextMenu from "./component/ContextMenu";
    import Search from "./component/Search";
    import Edit from "./component/Edit";

    export default {
        name: "home",
        components: {Edit, Login, Launcher, Folder, ContextMenu, Search},
        data() {
            return {
                types,
                tabs: [],
                activeTabName: 'home',

                searchWords: '', // 搜索词
                searchItems: [], // 搜索结果

                fixedItems: [], // 格式化后的置顶数据

                editForm: {
                    id: 0,
                    pid: 0,
                    type: "",
                    title: "",
                    content: "",
                    url: "",
                },

                editFormShow: false,

                renameForm: {
                    id: "",
                    title: "",
                },
                renameFormShow: false,

                shortnameForm: {
                    id: "",
                    name: "",
                },
                shortnameFormShow: false,

                iconForm: {
                    id: "",
                    icon: "",
                },
                iconFormShow: false,

                propObject: null,
                propShow: false,

                contentData: null,
                contentShow: false,

                mainHeight: '',
            }
        },

        mounted() {
            this.$store.dispatch("loadNotes")

            window.addEventListener("resize", () => {
                this.$store.commit("setWindowInnerSize", {height: window.innerHeight, width: window.innerWidth})
            })
        },

        methods: {
            noteIcon,
            noteIconError,
            pathName(pid) {
                let names = []

                for (let i = 0; i < 1000; i++) {
                    if (parseInt(pid)) {
                        let note = this.$store.state.notes.filter(v => v.id == pid)[0]

                        if (note) {
                            names.unshift(note.title)
                            pid = note.pid
                        } else {
                            break
                        }
                    } else {
                        break
                    }
                }

                return '/ ' + names.join(' / ')
            },


            handleIconUploaded(res, file) {
                console.log([res, file])
                this.iconForm.icon = res.data.icon
            },


            handleGlobalClick() {
                this.$store.commit('setContextMenuData', null)
            },
            handleRightClick(v, e, contextSource) {
                console.log(contextSource)
                e.preventDefault()

                this.$store.commit('setContextMenuData', {
                    Object: JSON.parse(JSON.stringify(v)),
                    Source: contextSource,
                    Left: e.clientX,
                    Top: e.clientY,
                })
            },
            handleContextMenuClick(note, action, argus) {
                if (action == actions.open) {
                    this.handleOpen(note)
                }

                if (action == actions.edit) {
                    this.handleEdit(note)
                }

                if (action == actions.rename) {
                    this.handleRenameShow(note)
                }

                if (action == actions.delete) {
                    this.handleDelete(note)
                }

                if (action == actions.create) {
                    this.handleAdd(argus, note.id)
                }

                if (action == actions.setIcon) {
                    this.handleIconShow(note)
                }

                if (action == actions.sendLauncher) {
                    this.handlePropShow(note)
                }

                if (action == actions.properties) {
                    this.handlePropShow(note)
                }

                if (action == actions.download) {
                    this.handlePropShow(note)
                }

                console.log([note, action, argus])
            },

            handlePropShow(note) {
                this.propObject = note
            },

            handlePropClose() {
                this.propObject = null
            },

            handleTabClick(tab, event) {
                console.log(tab, event);
            },

            deleteItem(items, id) {
                for (let i in items) {
                    let v = items[i]

                    if (v.id == id) {
                        items.splice(i, 1)
                        return true
                    }

                    if (v.children.length) {
                        if (this.deleteItem(v.children, id)) {
                            return true
                        }
                    }
                }
            },

            updateItem(items, data) {
                for (let i in items) {
                    let v = items[i]

                    if (v.id == data.id) {
                        items.splice(i, 1, Object.assign(v, data))
                        return true
                    }

                    if (v.children && v.children.length) {
                        if (this.updateItem(v.children, data)) {
                            return true
                        }
                    }
                }
            },

            handleOpen(data) {
                if (data.type == types.note) {
                    this.handleContentShow(data)
                }
                if (data.type == types.url) {
                    window.open(data.url, '_blank')
                }
                if (data.type == types.folder) {
                    let find = this.tabs.filter(v => v.id == data.id)[0]

                    if (!find) {
                        this.tabs.push(data)
                    }

                    this.activeTabName = 'TAB:' + data.id
                }
            },

            handleMove(node, target, position) {
                let orders = []

                if (position == 'inner') {
                    target.data.children.map(v => {
                        orders.push([v.id, target.data.id])
                    })
                } else {
                    if (parseInt(target.data.pid)) {
                        this.$refs.tree.getNode(target.data.pid).data.children.map(v => {
                            orders.push([v.id, target.data.pid])
                        })
                    } else {
                        orders = this.$refs.tree.data.map(v => {
                            orders.push([v.id, 0])
                        })
                    }
                }

                apiPost('/mark/orders', {orders})
            },

            handleFixed(data) {
                apiPost('/mark/fixed', {id: data.id, type: parseInt(data.fixed) ? 0 : 1}).then(result => {
                    if (result.code == 200) {
                        this.$store.dispatch("loadNotes")
                    }
                })
            },

            handleAdd(addType, pid) {
                let editForm = {
                    id: 0,
                    pid: pid || 0,
                    type: addType,
                    format: addType == types.note ? 'PLAIN' : '',
                    title: "",
                    content: "",
                    url: "",
                }

                this.$refs.editCom.handleEdit(editForm);
            },

            async handleEdit(item) {
                this.$refs.editCom.handleEdit(item);
            },

            handleRenameShow(item) {
                this.renameForm = {
                    id: item.id,
                    title: item.title,
                }
                this.renameFormShow = true
            },

            handleRenameClose() {
                this.renameForm = {
                    id: "",
                    title: "",
                }
                this.renameFormShow = false
            },

            handleRenameSave() {
                apiPut(`/note/${this.renameForm.id}/title/${encodeURIComponent(this.renameForm.title)}`, this.renameForm).then(result => {
                    if (result.code === 0) {
                        this.$store.dispatch("loadNotes")

                        this.handleRenameClose()
                    }
                })
            },

            handleIconShow(item) {
                this.iconForm = {
                    id: item.id,
                    icon: item.icon,
                }
                this.iconFormShow = true
            },

            handleIconClose() {
                this.iconForm = {
                    id: "",
                    icon: "",
                }
                this.iconFormShow = false
            },

            handleIconSave() {
                apiPost('/mark/icon', this.iconForm).then(result => {
                    if (result.code == 200) {
                        this.$store.dispatch("loadNotes")

                        this.handleIconClose()
                    }
                })
            },

            handleShortnameShow(item) {
                this.shortnameForm = {
                    id: item.id,
                    name: item.short,
                }
                this.shortnameFormShow = true
            },

            handleShortnameClose() {
                this.shortnameForm = {
                    id: "",
                    name: "",
                }
                this.shortnameFormShow = false
            },

            handleShortnameSave() {
                apiPost('/mark/short', this.shortnameForm).then(result => {
                    if (result.code == 200) {
                        this.$store.dispatch("loadNotes")

                        this.handleShortnameClose()
                    }
                })
            },

            handleSave() {
                if (typeof this.editForm.pid == 'object') {
                    this.editForm.pid = this.editForm.pid.pop()
                }

                apiPost('/note', this.editForm).then(result => {
                    if (result.code === 0) {
                        this.$store.dispatch("loadNotes")

                        this.handleEditFormClose()
                    }
                })
            },

            handleDelete(data) {
                apiDelete(`note/${data.id}`).then(result => {
                    if (result.code === 0) {
                        this.$store.dispatch("loadNotes")

                        this.handleEditFormClose()
                    }
                })
            },

            handleEditFormClose() {
                this.editFormShow = false
            },

            async handleContentShow(data) {
                let res = await apiGet(`/note/${data.id}`)
                if (res.code === 0) {
                    data.content = res.data.content

                    console.log(['handleContentShow', res.data])
                    this.contentData = res.data
                    this.contentShow = true
                } else {
                    return
                }
            },

            handleContentShowClose() {
                this.contentShow = false
            },

        }

    }
</script>

<style>
    html, body {
        background-color: #F8F9FA;
        height: 100%;
        margin: 0;
    }

    .list-box {
        padding-top: 24px;
    }

    .list-box .el-tree {
        background-color: #FFF;
        border-radius: 4px;
        box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 1px 3px 1px;
        margin: 0 auto;
        max-width: 960px;
        padding: 8px 0;
    }

    .list-box .el-tree-node__content {
        line-height: 40px;
        height: 40px;
        color: rgb(32, 33, 36);
    }

    .list-box .fixed-button {
        margin: 0 auto;
        max-width: 960px;
        position: relative;
    }

    .list-box .fixed-button > div {
        position: absolute;
        left: calc(100% + 12px);
        top: 0;
    }

    .list-box .fixed-button > div > div {
        margin-bottom: 6px;
    }

    .bar-box {
        align-items: center;
        background-color: rgb(51, 103, 214);
        color: #fff;
        display: flex;
        height: 56px;
    }

    .menu-box {
        background-color: #FFF;
        border-radius: 4px;
        box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 1px 3px 1px;
        margin: 24px auto 0;
        max-width: 960px;
        padding: 8px 0;
    }

    .menu-box .el-menu-item, .menu-box .el-submenu, .menu-box .el-submenu__title {
        height: 21px !important;
        line-height: 21px !important;
    }

    .menu-box .el-menu.el-menu--horizontal {
        display: inline-block;
        border-bottom: none;
    }

    .menu-box .el-menu--horizontal > .el-submenu.is-active .el-submenu__title, .menu-box .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: none;
    }

    .search-input {
        background: rgba(0, 0, 0, 0.22);
        border-radius: 2px;
        cursor: text;
        padding-inline-end: 0;
        width: 680px;
        align-items: center;
        display: flex;
        height: 40px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0.4, 0, 0.2, 1);
        margin: 0 auto;
    }

    .search-input .el-input__inner {
        background: rgba(0, 0, 0, 0);
        border: none;
        color: #fff;
        padding: 0 5px;
    }


    .search-input .el-icon-search {
        font-size: 16px;
        width: 16px;
        height: 16px;
        margin: 10px;
        cursor: pointer;
    }

    .search-input .el-icon-circle-close {
        font-size: 20px;
        width: 20px;
        height: 20px;
        margin: 10px;
        cursor: pointer;
    }

    .note-content-title {
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        padding: 20px;
        border-bottom: 1px solid #E0E0E0;
        margin: 20px;
    }

    .note-content-box {
        font-size: 1rem;
        white-space: pre-wrap;
        margin: 20px;
        overflow: auto;
        line-height: 1.65;
        letter-spacing: .02em;
        color: #000
    }

    .tabs-box {
        max-width: 960px;
        margin: 8px auto;
        background-color: #FFF;
        padding: 8px;
        box-sizing: border-box;
    }


    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
