<template>
    <div
            draggable="true"
            @dragstart.stop="handleDragStart"
            @drag.stop="handleDrag"
            @dragend.stop="handleDragEnd"
            @dragenter="handleDragEnter"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            @drop.stop="handleDrop"
            :class="{hover: dragOver && dropAble}"
    >
        <slot></slot>
    </div>
</template>

<script>
    import {apiPut} from "../../utils/api";
    import {types} from "../../utils/config";

    export default {
        name: "FileDrag",
        props: {
            data: Object
        },
        data() {
            return {
                dropAble: false,
                dragOver: false
            }
        },

        mounted() {
            this.dropAble = this.data.type == types.folder
        },

        methods: {
            // 拖拽前触发
            handleDragStart(e) {
                e.dataTransfer.setData('text', JSON.stringify(this.data))
            },
            // 拖拽前、拖拽结束之间，连续触发
            handleDrag() {
            },
            // 拖拽结束触发
            handleDragEnd() {
            },

            // 进入目标元素触发，相当于mouseover
            handleDragEnter() {
                this.dragOver = true
            },
            // 进入目标、离开目标之间，连续触发
            handleDragOver(e) {
                if (this.dropAble) {
                    e.preventDefault()
                }
            },
            // 离开目标元素触发，，相当于mouseout
            handleDragLeave() {
                this.dragOver = false
            },

            // 在目标元素上释放鼠标触发
            handleDrop(e) {
                this.dragOver = false

                let fromData = JSON.parse(e.dataTransfer.getData('text'))

                if (fromData) {
                    // 移动目标不可是自己
                    if (fromData.id == this.data.id) {
                        this.$message.warning('无效的操作')
                        return
                    }
                    let orders = []
                    orders.push([fromData.id, this.data.id])

                    apiPut(`/note/${fromData.id}/move/${this.data.id}`, {orders}).then(() => {

                        this.$store.dispatch("loadNotes")
                    })
                }

            },
        }
    }
</script>

<style scoped>

    .hover {
        background-color: rgb(220, 230, 250);
    }

</style>
