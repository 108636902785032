<template>
    <el-dialog
            title="登录"
            width="30%"
            :visible="loginShow"
            :before-close="handleLoginClose">
        <el-input v-model="loginForm.username" placeholder="请输入用户名" style="margin-bottom: 12px"></el-input>
        <el-input v-model="loginForm.password" placeholder="请输入密码" type="password"
                  style="margin-bottom: 12px"></el-input>
        <el-button type="primary" @click="handleLogin">登录</el-button>
    </el-dialog>
</template>

<script>
    import {apiPost} from "../../utils/api";

    export default {
        name: "Login",

        data() {
            return {
                loginForm: {
                    username: "",
                    password: ""
                },
            }
        },

        computed: {
            loginShow() {
                return this.$store.state.user === false
            }

        },

        mounted() {
        },

        methods: {
            handleLogin() {
                if (this.loginForm.username.trim() == '') {
                    this.$message.warning('请输入用户名')
                    return
                }

                if (this.loginForm.password.trim() == '') {
                    this.$message.warning('请输入密码')
                    return
                }

                apiPost('/auth/login', this.loginForm).then(result => {
                    if (result.code === 0) {
                        this.$store.commit('login', result.data)
                        this.$store.dispatch("loadNotes")
                    }
                })
            },
            handleLoginClose() {
            },
        }
    }
</script>

<style scoped>

</style>
