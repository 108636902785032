<template>
    <div class="context-menu-box" v-show="contextMenuData" ref="rightMenu" :style="{left,top}">
        <div v-if="contextMenuData && contextMenuData.Object.id > 0 && contextMenuData.Source != 'folder'">
            <div class="item" @click="trigger(actions.open)">打开</div>
            <div class="item" @click="trigger(actions.edit)" v-if="allowTypes([types.note, types.url])">编辑</div>
            <div class="item" @click="trigger(actions.rename)">重命名</div>
            <div class="item" @click="trigger(actions.delete)">删除</div>
            <div class="item" @click="trigger(actions.create, types.note)" v-if="allowTypes(types.folder)">新建笔记</div>
            <div class="item" @click="trigger(actions.create, types.folder)" v-if="allowTypes(types.folder)">新建文件夹</div>
            <div class="item" @click="trigger(actions.create, types.url)" v-if="allowTypes(types.folder)">添加网址</div>
            <div class="item" @click="trigger(actions.create, types.file)" v-if="allowTypes(types.folder)">上传文件</div>
            <div class="item" @click="trigger(actions.setIcon)">设置图标</div>
            <div class="item" @click="trigger(actions.sendLauncher)" v-if="parseInt(contextMenuData.Object.fixed)">
                从启动器取消
            </div>
            <div class="item" @click="trigger(actions.sendLauncher)" v-else>发送到启动器</div>
            <div class="item" @click="trigger(actions.download)" v-if="allowTypes(types.note)">下载</div>
            <div class="item" @click="trigger(actions.properties)">属性</div>
        </div>
        <div v-else-if="contextMenuData">
            <div class="item" @click="trigger(actions.create, types.note)" v-if="allowTypes(types.folder)">新建笔记</div>
            <div class="item" @click="trigger(actions.create, types.folder)" v-if="allowTypes(types.folder)">新建文件夹</div>
            <div class="item" @click="trigger(actions.create, types.url)" v-if="allowTypes(types.folder)">添加网址</div>
            <div class="item" @click="trigger(actions.create, types.file)" v-if="allowTypes(types.folder)">上传文件</div>
        </div>
    </div>
</template>

<script>
    import {actions, types} from '@/utils/config.js'
    import {mapState} from "vuex";

    export default {
        name: "ContextMenu",
        data() {
            return {
                types,
                actions,
            }
        },
        computed: {
            ...mapState(['contextMenuData']),
            left() {
                return this.$store.state.contextMenuData?.Left + 'px';
            },
            top() {
                return this.$store.state.contextMenuData?.Top + 'px';
            },
        },
        methods: {
            allowTypes(options) {
                if (typeof options === 'string') {
                    return this.contextMenuData.Object.type === options
                } else {
                    return options.indexOf(this.contextMenuData.Object.type) >= 0
                }
            },

            trigger(action, argus) {
                this.$emit('menu-click', this.contextMenuData.Object, action, argus)
            },

            actionOpen() {
                this.triggerMenuClick(actions.open)
            },
            actionDelete() {
                console.log('contextMenuDelete')
                this.handleDelete(this.contextMenuObject)
            },
            actionSendLauncher() {
                console.log('contextMenuSendLauncher')
                this.handleFixed(this.contextMenuObject)
            },
            actionProperties() {
                console.log('contextMenuProp')
                this.handlePropShow(this.contextMenuObject)
            },
            actionCreate(type) {
                console.log('contextMenuCreate')

                this.editForm = {
                    id: 0,
                    pid: 0,
                    type: type,
                    title: '',
                    content: "",
                    url: "",
                }
                this.editFormShow = true
            },
            actionSetIcon() {
                console.log('contextMenuIcon')
                this.handleIconShow(this.contextMenuObject)
            },

            actionDownload() {

            }
        }
    }
</script>

<style scoped>

    .context-menu-box {
        position: fixed;
        padding: 8px 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border: 1px solid #d7dae2;
        border-radius: 4px;
        background-color: #FFF;
        z-index: 999;
    }

    .context-menu-box .item {
        cursor: pointer;
        line-height: 36px;
        height: 36px;
        font-size: 14px;
        padding: 0 12px;
    }

    .context-menu-box .item:hover {
        background-color: rgb(245, 247, 250);
    }

</style>
