export const types = {
    url: 'URL',
    folder: 'FOLDER',
    note: 'NOTE',
    file: 'FILE',
}


export const actions = {
    open: 'Open',
    edit: 'Edit',
    rename: 'Rename',
    delete: 'Delete',
    create: 'Create',
    setIcon: 'SetIcon',
    sendLauncher: 'SendLauncher',
    properties: 'Properties',
    download: 'Download',
}
