<template>
    <el-container>
        <!-- 路径 -->
        <el-header class="path-box" height="">
            <div @click="handleFolderNameClick">{{ folderName }}</div>
            <el-breadcrumb>
                <el-breadcrumb-item></el-breadcrumb-item>
                <el-breadcrumb-item v-for="v in breadcrumb" v-bind:key="v.id">
                    <span @click="handleItemClick(v)" style="cursor: pointer">{{ v.title }}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </el-header>
        <el-container>
            <!-- 目录 -->
            <el-aside class="left-box">
                <el-tree ref="treeMenu" node-key="id" :data="list" :props="{label:'title'}">
                    <div class="left-menu-item" slot-scope="{ node, data }">
                        <file-drag class="left-menu-item" :data="data">
                            <div @click="handleItemClick(data)" @contextmenu.stop="handleRightClick(data, $event)">
                                <i class="el-icon-folder"></i>
                                {{ data.title }}
                            </div>
                        </file-drag>
                    </div>
                </el-tree>
                <div style="display: none">用于唤醒folders.competed{{folders.length}}</div>
            </el-aside>

            <!-- 列表 -->
            <el-main class="right-box">
                <div class="note-box" @contextmenu="handleRightClick('current', $event)">
                    <div v-for="v in items" v-bind:key="v.id"
                         @contextmenu.stop="handleRightClick(v, $event)"
                         @click="handleItemClick(v)"
                    >
                        <file-drag class="note-item" :data="v">
                            <img class="icon" :src="noteIcon(v)" @error="noteIconError"/>
                            {{ v.title }}
                            <span class="url" v-if="v.url">{{ decodeURI(v.url) }}</span>
                        </file-drag>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import {apiPost} from '@/utils/api.js'
    import {types} from '@/utils/config.js'
    import {formatTree,syncTree, noteIcon, noteIconError} from "@/utils/tool";
    import FileDrag from "./FileDrag";

    export default {
        name: "Folder",
        components: {FileDrag},
        props: {
            id: String,
            title: String,
        },

        data() {
            return {
                firstRendered: false,

                folderId: 0,
                folderName: '',
                currentId: 0,

                dragging: false,
                dragOver: {
                    id: null,
                    type: null
                },
                dragItem: null,

                list: [],
            }
        },

        updated() {
            if (!this.firstRendered) {
                this.firstRendered = true
            }
        },

        computed: {
            items() {
                return formatTree(this.$store.state.notes, this.currentId ?? this.folderId, false)
            },
            folders() {
                let data = formatTree(this.$store.state.notes, this.folderId, true)
                syncTree(this.list, data)
                console.log(data)
                console.log(this.list)
                return data
            },
            breadcrumb() {
                let items = []

                if (this.currentId && this.currentId != this.folderId) {
                    let findId = this.currentId

                    for (let i = 0; i < 1000; i++) {
                        for (const note of this.$store.state.notes) {
                            if (note.id == findId) {
                                items.unshift(note)
                                findId = note.pid
                                break
                            }
                        }

                        if (findId == 0 || findId == this.folderId) {
                            break;
                        }
                    }
                }

                return items
            },
        },

        mounted() {
            this.folderId = this.id || 0
            this.folderName = this.title || '轻松笔记'
            this.currentId = this.folderId
        },

        methods: {
            noteIcon,
            noteIconError,

            handleItemClick(v) {
                if (v.type == types.folder) {
                    this.currentId = v.id
                } else {
                    this.$emit('note-click', v)
                }
            },

            handleRightClick(v, e) {
                if (v == 'current') {
                    let id = this.currentId ?? this.folderId

                    if (id) {
                        v = this.$store.state.notes.filter(n => n.id == id)[0]
                    } else {
                        v = {id: "0", type: types.folder}
                    }

                    this.$emit('note-right-click', v, e, 'folder')

                    return
                }

                this.$emit('note-right-click', v, e, 'normal')
            },

            handleFolderNameClick() {
                this.currentId = this.folderId
            },

            handleDragStart(v) {
                this.dragOver.id = null
                this.dragOver.type = null
                this.dragging = true
                this.dragItem = v
            },
            handleDragEnd() {
                this.dragging = false
            },
            handleDragOver(v, type) {
                this.dragOver.id = v.id
                this.dragOver.type = type
            },
            handleDragLeve(v) {
                if (this.dragOver.id == v.id) {
                    this.dragOver.id = null
                    this.dragOver.type = null
                }
            },
            handleDragDrop() {
                if (this.dragItem.id != this.dragOver.id) {
                    // 删除
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id == this.dragItem.id) {
                            this.data.splice(i, 1)
                            break
                        }
                    }

                    // 放入
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].id == this.dragOver.id) {
                            if (this.dragOver.type == 'before') {
                                this.data.splice(i, 0, this.dragItem)
                            }

                            if (this.dragOver.type == 'after') {
                                this.data.splice(i + 1, 0, this.dragItem)
                            }

                            break
                        }
                    }

                    // 保存
                    let orders = this.data.map(v => v.id)
                    apiPost('/mark/fixed_orders', {orders})
                }
            },
            handleMove(node, target, position) {

                console.log([node, target, position])
                return

                // let orders = []
                //
                // if (position == 'inner') {
                //   target.data.children.map(v => {
                //     orders.push([v.id, target.data.id])
                //   })
                // } else {
                //   if (parseInt(target.data.pid)) {
                //     this.$refs.tree.getNode(target.data.pid).data.children.map(v => {
                //       orders.push([v.id, target.data.pid])
                //     })
                //   } else {
                //     orders = this.$refs.tree.data.map(v => {
                //       orders.push([v.id, 0])
                //     })
                //   }
                // }
                //
                // apiPost('/mark/orders', {orders})
            },

        }

    }
</script>

<style>
    .icon {
        float: left;
        margin: 6px 6px 0 0;
        height: 16px;
        width: 16px;
    }

    .stickName {
        line-height: 40px;
        height: 40px;
        background-color: rgb(245, 247, 250);
        font-size: 14px;
        margin: 6px 0;
        padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .note-box {
        flex: 1 1 auto;
        margin-top: 6px;
        overflow-y: auto;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    .note-box > div {
        overflow: hidden;
        width: 100%;
    }

    .note-item {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #525252;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 800px;
        box-sizing: border-box;
        white-space: pre;
        cursor: pointer;
    }

    .note-item > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .note-item .url {
        display: none;
        color: #9DA3AC;
        padding-left: 6px;
    }

    .note-item:hover {
        background-color: rgb(245, 247, 250);
    }

    .note-item:hover .url {
        display: inline;
    }

    .pointer {
        cursor: pointer
    }

    .left-box {
        height: 800px;
        border-right: 1px solid #DCDFE6;
        padding-right: 8px;

    }

    .right-box {
        display: flex;
        height: 800px;
        background-color: #FFF;
        padding-top: 0;
        width: 605px;
    }

    .path-box {
        display: flex;
        line-height: 38px;
        height: 40px;
        padding: 0;
        box-sizing: border-box;
        border-radius: 4px;
        #border: 1px solid #DCDFE6;
        margin-bottom: 12px;
        background-color: #F8F9FA;
    }

    .path-box > :first-child {
        cursor: pointer;
        background-color: #E0E0E0;
        padding: 0 12px;
        border-radius: 4px 0 0 4px;
    }

    .path-box > :last-child {
        flex: 1;
        line-height: 38px;
        height: 38px;
    }

    .flex-vertical {
        display: flex;
        flex-direction: column;
    }

    .flex1 {
        flex: 1 1 auto;
    }


    .left-menu-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        width: 100%;
        box-sizing: border-box;
        height: 26px;
        line-height: 26px;
    }

    .left-menu-item > :first-child {
        flex: 1;
    }

    .left-menu-item .note-url {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .left-menu-item .url {
        display: none;
        color: #9DA3AC;
    }

    .left-menu-item:hover .url {
        display: inline;
    }

</style>
