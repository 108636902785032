import { render, staticRenderFns } from "./FileDrag.vue?vue&type=template&id=9e3d870a&scoped=true&"
import script from "./FileDrag.vue?vue&type=script&lang=js&"
export * from "./FileDrag.vue?vue&type=script&lang=js&"
import style0 from "./FileDrag.vue?vue&type=style&index=0&id=9e3d870a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e3d870a",
  null
  
)

export default component.exports