/**
 * Created by PanJiaChen on 16/11/18.
 */

import {types} from './config'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
    })
    return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

export function formatTree(data, pid, onlyFolder) {
    let list = data.filter(v => v.pid == pid && v.type == types.folder)

    list = list.map(v => {
        v = JSON.parse(JSON.stringify(v))
        v.children = formatTree(data, v.id, onlyFolder)
        return v
    })

    if (!onlyFolder) {
        let notes = data.filter(v => v.pid == pid && v.type != types.folder)

        if (notes.length) {
            notes = notes.map(v => JSON.parse(JSON.stringify(v)))

            list.push(...notes)
        }
    }

    return list
}

export function syncTree(target, source) {
    target = target || []
    source = source || []

    // 清理删除元素
    target.map(v => {
        if (!source.filter(vv => vv.id == v.id).length) {
            target.splice(target.indexOf(v), 1);
        }
    })

    // 插入新增元素
    source.map((v, i) => {
        if (!target.filter(vv => vv.id == v.id).length) {
            target.splice(i, 0, v)
        }
    })

    // 同步属性
    target.map((v, i) => {
        let {children, ...s} = source[i]

        Object.assign(v, s)

        if (children && children.length) {
            syncTree(v.children, children)
        } else {
            v.children = []
        }
    })
}

export function noteIcon(note) {
    if (note) {
        if (note.icon) {
            return 'https://www.danegg.net/icon/' + note.icon
        }

        if (note.type == types.note) {
            return 'https://www.danegg.net/upload/icon/default/note.svg'
        }

        if (note.type == types.folder) {
            return 'https://www.danegg.net/upload/icon/default/folder.svg'
        }

        if (note.type == types.url) {
            return note.url.replace(/(?<!:|:\/)\/.*$/, '/favicon.ico')
        }
    }

    return 'https://www.danegg.net/upload/icon/default/default.svg'
}

export function noteIconError(e) {
    e.target.src = noteIcon()
}
